<template>
    <div class="post">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="image">
                        <b-img
                            src="https://scontent.fkrt3-1.fna.fbcdn.net/v/t1.0-9/s960x960/93076584_2875297375893179_8277215506327404544_o.jpg?_nc_cat=100&ccb=3&_nc_sid=8024bb&_nc_ohc=DtA6j7ZHtQYAX9pF4Vs&_nc_ht=scontent.fkrt3-1.fna&tp=7&oh=18986d3d7ae9899950b302aa3c4815c2&oe=604DB060"
                            fluid alt="Responsive image"></b-img>
                    </div>
                    <div class="title">
                        <h3 class="text-right">الدم هو البجمعنا </h3>
                    </div>
                    <div class="post-body text-right">
                        <p>
                            الدم هو البجمعنا. لا بفرِّق بين لون ولا دين ولا عرق.
                            لانو الدم حياة... ،♥️
                            ولأنو الحوجة لسة مستمرة.. ولانو كل فصائل الدم قاطعة في بنك الدم القومي المركزي..
                            بنطلب من اي متبرع بيحمل اي فصيلة.. تم 3 شهور من آخر تبرع وماعندو اي مشكلة صحية..

                            بنطلب منكم تشاركون الحياة وتتبرعو بي دمكم..
                            وانتي كمان ممكن تتبرعي بسي لازم يكون دمك اكتر من 85 ٪ ووزنك اكتر من 55 كجم. 🤩
                            من 9 صباحا لغاية 2 ضهر..

                            اعملو احتياطاتكم اللازمة.. واحنا بدورنا حنوفر ليكم سُبل السلامة أن شاء الله..
                            خدمات نقل الدم.. حاجة منقذة للحياة.. ما حتتوقف أبدا لأنها طوارئ..
                            شكرا ليكم.. والله لا يحوج زول لي دم.. وان شاء الله الفترة دي تعدي على خير.. 💚💚💚
                            للإستفسار
                            0121964719
                        </p>
                    </div>
                </div>
                <div class="col-md-4">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss">
    .post {
        margin-top: 3%;
        direction: rtl;

        .title {
            margin: 15px 0;
        }

        .post-body p {
            line-height: 2.3;
            color: #222;
            font-size: 16px;
        }
    }
</style>